import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { component as Footer } from "@layout/footer"
import { component as Header } from "@layout/header"

import * as styles from "./styles.module.scss"

import Layout from "@layout/no-header-footer"
import TwoColumnCallout from "@components/two-column-callout"
import Button from "@components/button"
import Grid from "@layout/grid"
import Card from "@components/card"
import Container from "@layout/container"

class BlankTemplatePage extends React.Component {
  // data alias
  page = this.props.data.datoCmsPageTruckVan

  render() {
    const bodyContent =
      this.page.bodyNode && this.page.bodyNode.childMarkdownRemark
        ? this.page.bodyNode.childMarkdownRemark.html
        : this.page.body

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>

        <Header {...this.props.data.menu} />

        <div className={styles.blankTemplate}>
          <Layout seoMetaTags={this.page.seoMetaTags}>
            <div className={styles.body}>
              <h1>{this.page.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: bodyContent
                }}
              ></div>
            </div>
          </Layout>

          {this.page.blocks &&
            this.page.blocks.map((block, index) => {
              const type = block.internal.type

              if (type === "DatoCmsContentCallout") {
                const callouts = block.blocks.map((item) => {
                  const calloutBody =
                    `${item.heading ? `<h2>${item.heading}</h2>` : ""}` +
                    `${item.bodyNode ? item.bodyNode.childMarkdownRemark.html : item.body}`

                  return {
                    body: calloutBody,
                    image: {
                      childImageSharp: {
                        gatsbyImageData: {
                          ...item.image.gatsbyImageData
                        }
                      }
                    }
                  }
                })

                return (
                  <Layout key={index}>
                    <div className={styles.twoColumnCallout}>
                      <TwoColumnCallout callouts={[...callouts]} heading={block.heading} />
                    </div>
                  </Layout>
                )
              } else if (type === "DatoCmsCardGrid") {
                let layout = 3
                if (block.layout === "Halves") layout = 2
                if (block.layout === "Full") layout = 1

                return (
                  <div className={styles.cardGrid}>
                    <Container
                      key={index}
                      hasChildren
                      theme={block.theme.toLowerCase().replace(/\s+/g, "-").replace(/_/g, "-")}
                      notch={block.notch.toLowerCase().replace(/\s+/g, "-")}
                    >
                      <div className={styles.cardGridTop}>
                        {block.heading && <h2>{block.heading}</h2>}
                        {block.button[0] && (
                          <Button
                            theme={block.button[0].theme.toLowerCase().replace(/\s+/g, "-")}
                            to={block.button[0].link}
                            className={styles.button}
                          >
                            {block.button[0].label}
                          </Button>
                        )}
                      </div>

                      <Grid
                        items={`${block.blocks.length}`}
                        hasChildren
                        gutter="15"
                        layout={`${layout}`}
                      >
                        {block.blocks.map((card, idx) => {
                          const cardBody =
                            card.bodyNode && card.bodyNode.childMarkdownRemark
                              ? card.bodyNode.childMarkdownRemark.html
                              : card.body
                          return (
                            <Card
                              key={idx}
                              theme={card.theme.toLowerCase().replace(/\s+/g, "-")}
                              heading={card.heading}
                              url={card.link}
                              buttonLabel={card.buttonLabel}
                              image={{
                                childImageSharp: {
                                  gatsbyImageData: {
                                    ...card.image.gatsbyImageData
                                  }
                                }
                              }}
                            >
                              <div
                                className={styles.card_wrapper}
                                dangerouslySetInnerHTML={{ __html: cardBody }}
                              ></div>
                            </Card>
                          )
                        })}
                      </Grid>
                    </Container>
                  </div>
                )
              }

              return null
            })}
        </div>

        <Footer {...this.props.data.footer} />
      </>
    )
  }
}

export default BlankTemplatePage

export const query = graphql`
  query BlankTemplateQuery($permalink: String!) {
    ...MegaMenu
    ...Footer

    datoCmsPageTruckVan(permalink: { eq: $permalink }) {
      body
      seoMetaTags {
        tags
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      title
      permalink
      blocks {
        ... on DatoCmsContentCallout {
          heading
          internal {
            type
          }
          blocks {
            heading
            body
            bodyNode {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
